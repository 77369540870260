import React from "react"

export default function Bio() {
  return (
    <>
      Software Engineer &bull; Burrito Enthusiast
      <br />
      Bitcoin &bull; Web &bull; 3D &bull; Finance
    </>
  )
}
